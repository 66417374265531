import { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

export function TournamentBoard() {
  
  const urlParams = useParams();
  const { divisionIds, seasonId, page, speed } = urlParams;
  const divisionIdArray = useMemo(() => divisionIds.split(','), [divisionIds]);
  const iframe1 = useRef();
  const iframe2 = useRef();
  const iframes = [ iframe1, iframe2 ];

  // url generator
  const url = ( s, d ) => `https://gamesheetstats.com/seasons/${s}/${page}?filter[division]=${d}&configuration[logo]=false&configuration[navigation]=false&configuration[filters]=false&configuration[standings-cols]=rk,gp,w,l,t,team,logo,pts,gf,ga,diff,otw,otl`
  const r1 = useMemo(() => window.innerHeight < 1200 ? 0.55 : 1, [window.innerHeight])

  useEffect(() => {
    
    let currentPosition = 0;
    
    iframe1.current.src = url(seasonId, divisionIdArray[ currentPosition++ ]);

    if(divisionIdArray.length > 1){
      iframe2.current.src = url(seasonId, divisionIdArray[ currentPosition++ ]);
    }

    const interval = setInterval(() => {

      const fadingOut = iframes[ (currentPosition-2) % 2 ];
      const fadingIn = iframes[ (currentPosition-1) % 2 ];

      // fadingIn.current.style.opacity = 1;
      // fadingOut.current.style.opacity = 0;

      setTimeout(() => {
        fadingOut.current.src = url(seasonId, divisionIdArray[ currentPosition % divisionIdArray.length ])
      }, 500);
      
      currentPosition++;

    }, Math.max(5000, speed*1000));

    return () => clearInterval(interval)

  }, [ seasonId, divisionIds ]);

  return (
    <>
      <div style={{ 
        display:'flex', 
        alignItems:'center',
        justifyContent:'center', 
        backgroundImage:'url(https://carhahockeyworldcup.ca//wp-content/uploads/2019/07/texture-semi-transparent.png)' ,
        padding: '40px'
      }}>
        <img style={{ width:436*r1*0.753, height:300*r1 }} src="https://storage.googleapis.com/marketing-site-blog-media/gslogo_primaryblack_small.png" />
        <img style={{ width:512*r1, height:424*r1, display:'block', padding:'0 180px' }} src="https://carhahockeyworldcup.ca/wp-content/uploads/2020/10/World-Cup-Logo-NoDate1.png" />
        <img style={{ width:763*r1*0.552, height:300*r1 }} src="https://carhahockeyworldcup.ca/wp-content/uploads/2023/01/mol-secondary-identity-founded-rgb-768x543.png" />
      </div>
      
      <div style={{ display:'flex', paddingTop:15 }}>
        <div>
          <iframe ref={iframe1} title="iframe1"/>
        </div>
        <div>
          <iframe ref={iframe2} title="iframe2"/>
        </div>
      </div>

      <div style={{ backgroundColor:"#36383d", position:'fixed', bottom: 0, left: 0, right: 0, textAlign:'center' }}>
        <img src="https://gbtll.ca/public/images/common/galleries/1220/gslogo_horizontal_tagline_color_darkbg_large_copy-8.png" style={{ width:477*r1, height:185*r1 }} />
      </div>

      
    </>
  );

}